// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/svg/duble-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".checked-items__wrapper[data-v-a8a3e82a]{width:100%;margin:35px 0;max-width:800px}.checked-items__wrapper ul[data-v-a8a3e82a]{list-style:disc;padding-left:18px}.checked-items__wrapper li[data-v-a8a3e82a],.checked-items__wrapper p[data-v-a8a3e82a]{font-size:16px;font-weight:400;line-height:22px}.checked-items__wrapper .checked[data-v-a8a3e82a]{position:relative}.checked-items__wrapper .checked[data-v-a8a3e82a]:before{content:\"\";position:absolute;width:23px;height:13px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;left:0;top:0}.checked-items__wrapper li[data-v-a8a3e82a]{padding-left:40px}@media screen and (max-width:900px){.checked h2[data-v-a8a3e82a]{font-size:17px;font-weight:900;line-height:18px}}", ""]);
// Exports
module.exports = exports;
