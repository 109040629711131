import Vue from 'vue';

//sections
import mainSlider from '@/components/sections/main-slider/index.vue';
import cover from '@/components/sections/cover/index.vue'
import partner from '@/components/sections/partner/index.vue'
import products from "@/components/sections/products/index.vue";
import articles from '@/components/sections/articles/index.vue'
import callBack from "@/components/sections/call-back/index.vue";
import seoText from "@/components/sections/seo-text/index.vue";
import description from "@/components/sections/description/index.vue";
import imageText from "@/components/sections/image-text/index.vue";
import twoImages from "@/components/sections/two-images/index.vue";
import monoSection from "@/components/sections/mono/index.vue";
import checkedItems from "@/components/sections/checked-items/index.vue";
import solutionSlider from "@/components/sections/solution-slider/index.vue";
import simpleItems from "@/components/sections/simple-items/index.vue";
import roofCalculation from "@/modules/product-card/pages/roof-calculation/index.vue";
import table from "@/components/sections/table/index.vue"
import videoSlider from "@/components/sections/video-slider/index.vue";
import colorPalate from "@/components/sections/color-palate/index.vue";
import pictureUnderPicture from "@/components/sections/picture-under-picture/index.vue";
import oneText from "@/components/sections/one-text/index.vue";
import textSlider from "@/components/sections/text-slider/index.vue";
import sliderCenterMode from "@/components/sections/slider-center-mode/index.vue";
import beforeAfterSlider from "@/components/sections/before-after-slider/index.vue";
import sliderText from "@/components/sections/slider-text/index.vue";
import sliderBadge from "@/components/sections/slider-badge/index.vue";
import badges from "@/components/sections/badges/index.vue";
import sliderItems from "@/components/sections/slider-items/index.vue";
import warmhouses from "@/components/sections/warmhouses/index.vue";
import banner from "@/components/sections/banner/index.vue";
import color from "@/components/sections/color/index.vue";
import titleVideo from "@/components/sections/title-video/index.vue";
import categoryList from "@/components/sections/category-list/index.vue";
import orderProduct from "@/components/sections/order-product/index.vue";
import videoList from "@/components/sections/video-list/index.vue";

Vue.component('video-list', videoList)
Vue.component('order-product', orderProduct)
Vue.component('category-list', categoryList)
Vue.component('title-video', titleVideo)
Vue.component('color', color)
Vue.component('banner', banner)
Vue.component('warmhouses', warmhouses)
Vue.component('slider-items', sliderItems)
Vue.component('badges', badges)
Vue.component('slider-badge', sliderBadge)
Vue.component('slider-text', sliderText)
Vue.component('before-after-slider', beforeAfterSlider)
Vue.component('slider-center-mode', sliderCenterMode)
Vue.component('text-slider', textSlider)
Vue.component('one-text', oneText)
Vue.component('picture-under-picture', pictureUnderPicture)
Vue.component('color-palate', colorPalate)
Vue.component('video-slider', videoSlider)
Vue.component('table-section', table)
Vue.component('roof-calculation', roofCalculation)
Vue.component('simple-items', simpleItems);
Vue.component('solution-slider', solutionSlider);
Vue.component('checked-items', checkedItems);
Vue.component('mono-section', monoSection);
Vue.component('two-images', twoImages);
Vue.component('image-text', imageText);
Vue.component('description', description);
Vue.component('main-slider', mainSlider);
Vue.component('cover', cover);
Vue.component('partner', partner);
Vue.component('products', products);
Vue.component('articles', articles);
Vue.component('call-back', callBack);
Vue.component('seo-text', seoText);

//components
import Loader from '@/components/misc/loader.vue';
import mainButton from '@/components/main-button/index.vue';
import allResult from "@/components/all-result/index.vue";
import breadcrumbs from "@/components/breadcrumbs/index.vue";
import mainSelect from "@/components/main-select/index.vue";
import timer from '@/components/timer/index.vue'
import catalogCategoryCard from "@/components/catalog-category-card/index.vue";
import productCard from "@/components/product-card/index.vue";
import optionReliz from '@/modules/product-card/pages/roof-calculation/components/option/index.vue'
import productCardLoading from '@/components/product-card-loading/index.vue'
import PopupWrap from '@/components/misc/popup-wrap.vue';
import smallLoader from "@/components/misc/small-loader.vue";
import mainBanner from "@/components/main-banner/index.vue";
import videoPlayer from "@/components/video-player/index.vue";
import buttonLoader from "@/components/misc/button-loader.vue";
import searchLoader from "@/components/misc/search-loader.vue";
import circleLoader from "@/components/misc/circle-loader.vue";


Vue.component('circle-loader', circleLoader);
Vue.component('search-loader', searchLoader);
Vue.component('button-loader', buttonLoader);
Vue.component('video-player', videoPlayer);
Vue.component('main-banner', mainBanner);
Vue.component('small-loader', smallLoader);
Vue.component('loader', Loader);
Vue.component('main-button', mainButton);
Vue.component('all-result', allResult);
Vue.component('breadcrumbs', breadcrumbs);
Vue.component('main-select', mainSelect);
Vue.component('timer', timer);
Vue.component('catalog-category-card', catalogCategoryCard);
Vue.component('product-card', productCard);
Vue.component('option-reliz', optionReliz);
Vue.component('product-card-loading', productCardLoading);
Vue.component('popup-wrap', PopupWrap);


//packages
import VueCarousel from 'vue-carousel';
import VueObserveVisibility from 'vue-observe-visibility'
import {InlineSvgPlugin} from 'vue-inline-svg';
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask';
import VueSimpleRangeSlider from 'vue-simple-range-slider';
import VueHead from 'vue-head'
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
// import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css'
import VueGallery from 'vue-gallery';
import ProductZoomer from 'vue-product-zoomer'
import {ColourPicker} from 'vue-colour-picker'
import VueLazyload from 'vue-lazyload'
import * as VueAos from "vue-aos";
import infiniteScroll from 'vue-infinite-scroll'
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueDatePicker);
Vue.use(VueHead)
Vue.use(infiniteScroll)
Vue.use(VueAos)
Vue.use(VueLazyload)
Vue.use(ProductZoomer)
Vue.use(VueTheMask);
Vue.use(Vuelidate)
Vue.use(VueObserveVisibility)
Vue.use(VueCarousel);
Vue.use(InlineSvgPlugin);
Vue.component('colour-picker', ColourPicker);
Vue.component('vue-gallery', VueGallery);
Vue.component('vue-simple-range-slider', VueSimpleRangeSlider);
Vue.component('main-button', mainButton);
Vue.component('v-pagination', window['vue-plain-pagination']);

Vue.use(VueSmoothScroll, {
    duration: 700,
    updateHistory: false,
    offset: 0,
})
