import {mapGetters, mapMutations} from "vuex";

//sections
export default {
    name: "video-slider",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            isPlayVideo: false,
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({})
    },

    methods: {
        ...mapMutations({}),
        playVideo() {
            this.isPlayVideo = !this.isPlayVideo
        },
        carouselNav($event, direction) {
            const carousel = this.$refs['video-slider'];
            carousel.advancePage(direction);
        },
    }
}
