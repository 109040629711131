import {mapActions, mapGetters, mapMutations} from "vuex";
import characteristics from "@/modules/product-card/pages/characteristics/index.vue";
import archedCanopy from '@/modules/product-card/pages/roof-calculation/pages/arched_canopy/index.vue'
import gableCanopy from '@/modules/product-card/pages/roof-calculation/pages/gable_canopy/index.vue'
import singlePitchedCanopy from '@/modules/product-card/pages/roof-calculation/pages/single_pitched_canopy/index.vue'
//sections
export default {

    name: "roof",
    components: {
        characteristics,
        archedCanopy,
        gableCanopy,
        singlePitchedCanopy
    },
    data() {
        return {
            checkedRoof: 'arched_canopy'
        }
    },
    watch: {},
    beforeUpdate() {

    },
    mounted() {

    },
    created() {

        this.getCalculators().then(() => {
            if (this.currentProduct) {
                document.title = `${this.currentProduct.title + ' - ' + this.$t('productCard.roofCalculation.title')}`
            }
            if (window.innerWidth <= 550) {
                let menu = document.querySelector('.roof__wrapper__menu_section')
                menu.style.overflowX = 'visible';
                menu.classList.add('animatedMenu')
                setTimeout(() => {
                    menu.style.overflowX = 'scroll';
                }, 5000)
            }
        })
    },
    computed: {
        ...mapGetters({
            calculators: 'constructors/calculators',
            specifications: 'constructors/calculatorSpecifications',
            currentProduct: 'catalog/currentProduct',
            constructorLoading: 'constructors/constructorLoading'
        }),
        showLoader() {
            return this.constructorLoading && window.innerWidth > 500
        }
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({
            getCalculators: 'constructors/CET_CALCULATORS',
            getSpecifications: 'constructors/CET_ARCH_SPECIFICATION'
        }),
        checkRoof(item) {
            this.checkedRoof = item
        },

    }

}
