import {mapMutations, mapGetters, mapActions} from "vuex";

export default {
    name: "product-question",
    props: {},
    data() {
        return {
            payload: {
                message: '',
                product_id: '',
                validMessage: false,
            }
        }
    },
    watch: {
        'payload.message'() {
            this.$refs.questionError.textContent = ''
            this.validMessage = false
        },
    },
    created() {
        this.payload.product_id = this.currentProduct.id
        if (this.questionId) {
            this.payload.parent_id = this.questionId
        }
    },
    computed: {
        ...mapGetters({
            currentProduct: 'catalog/currentProduct',
            questionId: 'card/questionId'
        })
    },
    methods: {
        ...mapActions({
            sendQuestion: 'card/WRITE_QUESTION',
        }),
        ...mapMutations({
            closeQuestionPopup: 'card/SHOW_QUESTION_POPUP'
        }),
        sendUserQuestion() {
            let errorText = this.$refs.questionError
            if (!this.payload.message.length) {
                this.validMessage = true
                errorText.textContent = this.$t('callback.enterText')
            }
            if (this.payload.message.length <= 10) {
                this.validMessage = true
                errorText.textContent = this.$t('validation.minLength', {'size': 10})
            }
            if (!this.validMessage) {
                this.sendQuestion(this.payload).then(() => {
                    this.$toasted.success(this.$t('productCard.questions.success'));
                    this.closeQuestionPopup(false)
                    document.getElementById('html').classList.remove('hide')
                }).catch(error => {
                    this.$toasted.error('error', error);
                })
            }
        }
    },
}
