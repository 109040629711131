import {$http} from '@/utils/https'

import {
    FILTERS_LOADING,
    ONE_PROJECT_LOADING,
    PROJECTS_LOADING,
    SET_FILTERS,
    SET_ONE_PROJECT,
    SET_PROJECT_PAGE,
    SET_PROJECTS,
    SET_PROJECTS_TILE_FILTERS,
    SET_TILES
} from '../mutation-types';

import {GET_FILTERS, GET_ONE_PROJECT, GET_PROJECTS, GET_PROJECTS_TILE_FILTERS, GET_TILES} from '../action-types';

const state = {
    projects: [],
    projectsLoading: false,
    filters: '',
    filtersLoading: false,
    oneProjectLoading: false,
    projectData: [],
    limit: 9,
    tileFilters: '',
    tilesCollection: []
};

const getters = {
    tilesCollection: state => state.tilesCollection,
    tileFilters: state => state.tileFilters,
    projects: state => state.projects,
    projectsLoading: state => state.projectsLoading,
    filters: state => state.filters,
    filtersLoading: state => state.filtersLoading,
    oneProjectLoading: state => state.oneProjectLoading,
    projectData: state => state.projectData
};

const actions = {

    [GET_PROJECTS]: async ({commit}, urlQuery) => {
        commit(PROJECTS_LOADING, true);
        try {
            const response = await $http.get(`v1/projects?page=1&per_page=${state.limit}&${urlQuery}`);
            commit(SET_PROJECTS, response.data);

        } catch (e) {
            throw e;
        } finally {
            commit(PROJECTS_LOADING, false);
        }
    },

    [GET_FILTERS]: async ({commit}) => {
        commit(FILTERS_LOADING, true);
        try {
            const response = await $http.get(`v1/projects/get-filter`);
            commit(SET_FILTERS, response.data.data);

        } catch (e) {
            throw e;
        } finally {
            commit(FILTERS_LOADING, false);
        }
    },
    [GET_PROJECTS_TILE_FILTERS]: async ({commit}, payload) => {
        // commit(FILTERS_LOADING, true);
        try {
            const response = await $http.get(`v1/projects/get-filter?for_section=${payload.section}`);
            commit(SET_PROJECTS_TILE_FILTERS, response.data.data);

        } catch (e) {
            throw e;
        } finally {
            // commit(FILTERS_LOADING, false);
        }
    },
    [GET_TILES]: async ({commit}, payload) => {
        // commit(PROJECTS_LOADING, true);
        try {

            const response = await $http.get(`v1/projects?for_section=${payload.section}&${payload.urlQuery}`);
            commit(SET_TILES, response.data);

        } catch (e) {
            throw e;
        } finally {
            // commit(PROJECTS_LOADING, false);
        }
    },
    [GET_ONE_PROJECT]: async ({commit}, slug) => {
        commit(ONE_PROJECT_LOADING, true);
        try {
            const response = await $http.get(`v1/projects/${slug}`);
            commit(SET_ONE_PROJECT, response.data.data);

        } catch (e) {
            throw e;
        } finally {
            commit(ONE_PROJECT_LOADING, false);
        }
    },


};

const mutations = {
    [SET_PROJECTS](state, data) {
        state.projects = data
    },
    [PROJECTS_LOADING](state, status) {
        state.projectsLoading = status
    },
    [SET_FILTERS](state, data) {
        state.filters = data
    },
    [FILTERS_LOADING](state, status) {
        state.filtersLoading = status
    },
    [ONE_PROJECT_LOADING](state, status) {
        state.oneProjectLoading = status
    },
    [SET_ONE_PROJECT](state, data) {
        state.projectData = data
    },
    [SET_PROJECT_PAGE](state) {
        state.limit += 9
    },
    [SET_PROJECTS_TILE_FILTERS](state, data) {
        state.tileFilters = data
    },
    [SET_TILES](state, data) {
        state.tilesCollection = data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
