//setting
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_PAGE_CONTENT = 'GET_PAGE_CONTENT'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const GET_VIDEO_GALLERY = 'GET_VIDEO_GALLERY'
export const GET_MAIN_CONTACTS = 'GET_MAIN_CONTACTS'
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY'
export const GET_SITEMAP = 'GET_SITEMAP'


//order
export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS'
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS'
export const CAT_GET_TOWNS = 'CAT_GET_TOWNS'
export const CAT_GET_DEPARTMENS = 'CAT_GET_DEPARTMENS'
export const DELIVERY_GET_TOWNS = 'DELIVERY_GET_TOWNS'
export const DELIVERY_GET_DEPARTMENS = 'DELIVERY_GET_DEPARTMENS'
export const GET_SERVICES_LIST = 'GET_SERVICES_LIST'
export const GET_PAYMENTS_TYPE = 'GET_PAYMENTS_TYPE'
export const GET_DELIVERIES_LIST = 'GET_DELIVERIES_LIST'
export const CALCULATION_TYPE = 'CALCULATION_TYPE'
export const SEND_IPN = 'SEND_IPN'
export const SEND_ORDER = 'SEND_ORDER'
export const SEND_FAST_ORDER = 'SEND_FAST_ORDER'
export const GET_TOWNS_LIST = 'GET_TOWNS_LIST'
export const GET_DEPARTMENTS_LIST = 'GET_DEPARTMENTS_LIST'
export const GET_ADDRESS_LIST = 'GET_ADDRESS_LIST'

//catalog
export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_ONE_CATEGORY = 'GET_ONE_CATEGORY'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_CURRENT_CATEGORY = 'GET_CURRENT_CATEGORY'
export const GET_PRODUCT_FILTERS = 'GET_PRODUCT_FILTERS'
export const GET_FILTERED_PRODUCTS = 'GET_FILTERED_PRODUCTS'
export const CATALOG_SEARCH = 'CATALOG_SEARCH'
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH'

//card
export const WRITE_QUESTION = 'WRITE_QUESTION'
export const WRITE_COMMENT = 'WRITE_COMMENT'
export const GET_PRODUCT_WITH_ATTRIBUTE = 'GET_PRODUCT_WITH_ATTRIBUTE'
export const GET_DELIVERY_TYPE = 'GET_DELIVERY_TYPE'

//auth
export const FETCH_USER_DATA = 'FETCH_USER_DATA'
export const REGISTER_SEND_CODE = 'REGISTER_SEND_CODE'
export const REGISTER_CHECK_CODE = 'REGISTER_CHECK_CODE'
export const LOGOUT = 'LOGOUT'

//manuals
export const GET_MANUALS = 'GET_MANUALS'
export const GET_MANUAL_PAGE = 'GET_MANUAL_PAGE'
export const GET_MANUAL_PAGE_OR_GROUP = 'GET_MANUAL_PAGE_OR_GROUP'

//articles
export const GET_ARTICLES = 'GET_ARTICLES'
export const GET_ARTICLE_PAGE = 'GET_ARTICLE_PAGE'

//projects
export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_FILTERS = 'GET_FILTERS'
export const GET_ONE_PROJECT = 'GET_ONE_PROJECT'
export const GET_PROJECTS_TILE_FILTERS = 'GET_PROJECTS_TILE_FILTERS'
export const GET_TILES = 'GET_TILES'

//contacts
export const GET_CONTACTS = 'GET_CONTACTS'

//landings
export const GET_LENDING = 'GET_LENDING'

//basket
export const UPDATE_BASKET = 'UPDATE_BASKET'
export const GET_BASKET = 'GET_BASKET'
export const BASKET_PRODUCT_ACTION = 'BASKET_PRODUCT_ACTION'
export const SELECT_SERVICES = 'SELECT_SERVICES'

//constructors
export const GET_CONSTRUCTORS = 'GET_CONSTRUCTORS'
export const GET_CONSTRUCTORS_PAGE = 'GET_CONSTRUCTORS_PAGE'
export const GET_BUILDING_ELEMENTS = 'GET_BUILDING_ELEMENTS'
export const CET_CALCULATORS = 'CET_CALCULATORS'
export const CET_ARCH_SPECIFICATION = 'CET_ARCH_SPECIFICATION'
export const GET_SPECIFICATIONS = 'GET_SPECIFICATIONS'
export const GET_SNOW_LOAD_CATEGORIES = 'GET_SNOW_LOAD_CATEGORIES'
export const GET_CURRENT_CALCULATOR = 'GET_CURRENT_CALCULATOR'
// export const GET_SNOW_LOAD_CATEGORIES=''


export const UPDATE_BASKET_CURRENT = 'UPDATE_BASKET_CURRENT'


//profile
export const GET_USER_DATA = 'GET_USER_DATA'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const GET_LEGAL_USERS = 'GET_LEGAL_USERS'
export const GET_OWNERSHIP_TYPES = 'GET_OWNERSHIP_TYPES'
export const UPDATE_LEGAL_USER = 'UPDATE_LEGAL_USER'
export const CREATE_LEGAL_USER = 'CREATE_LEGAL_USER'
export const GET_CURRENT_ORDERS = 'GET_CURRENT_ORDERS'
export const GET_HISTORY_ORDERS = 'GET_HISTORY_ORDERS'
export const GET_DISCOUNTS = 'GET_DISCOUNTS'
export const GET_DISCOUNT_DESCRIPTION = 'GET_DISCOUNT_DESCRIPTION'
export const GET_CATALOG = 'GET_CATALOG'
export const GET_GREENHOUSE = 'GET_GREENHOUSE'
export const GET_PRODUCT_GROUP = 'GET_PRODUCT_GROUP'
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_CURRENT_DOCUMENT = 'GET_CURRENT_DOCUMENT'
export const GENERATE_DOCUMENT_PDF = 'GENERATE_DOCUMENT_PDF'

//pricing
export const GET_PRICING_LIST = 'GET_PRICING_LIST'





