export default {
    name: "one-text",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {}
}
