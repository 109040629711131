<template>
  <transition name="bounce">
    <div class="popup">
      <div class="popup-wrap">
        <div class="popup-bg" @click="closePopup()"></div>
        <div class="popup-container" :style="{ 'max-width': maxWidth + 'px' }">
          <div class="popup-top">
            <div class="popup-top__title" :class="{'positionLeft':positionLeft}">
              {{ title }}
            </div>
            <button class="popup-close" @click="closePopup()">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.3827 6.76953L23.2311 7.61793L7.61793 23.2299L6.76953 22.3821L22.3827 6.76953Z"
                      fill="#B4B4B4"/>
                <path d="M7.61793 6.76953L23.2311 22.3815L22.3827 23.2305L6.76953 7.61853L7.61793 6.76953Z"
                      fill="#B4B4B4"/>
              </svg>
            </button>
          </div>
          <div class="popup-content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'popup-wrap',
  data() {
    return {
      isOpen: true,
    }
  },
  props: {
    title: {},
    positionLeft: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 840
    }
  },
  created() {
    document.getElementById('html').classList.add('hide')

  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        document.getElementById('html').classList.add('hide')
      } else {
        document.getElementById('html').classList.remove('hide')
      }
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/vars";

.hide {
  overflow: hidden !important;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;

  .positionLeft {
    margin-top: 0;
    text-align: left;
  }

  &-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, .5);
  }

  &-wrap {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
    overflow-y: auto;
  }

  &-container {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 840px;
    background: #fff;
    padding: 25px 32px;
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-content {

  }

  &-top {
    width: 100%;
    padding-bottom: 32px;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__title {
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-top: 35px;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #171616;

    }
  }

  &-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    @include transition(all, 0.3s, cubic-bezier(0.65, -0.25, 0.25, 1.95));

    svg {
      width: 30px;
      height: 30px;
      //color: #9a9a9a;
    }

    &:hover {
      transform: rotate(90deg) scaleX(1.15);
      @include transition(all, 0.3s, cubic-bezier(0.65, -0.25, 0.25, 1.95));

      svg {
        path {
          fill: #171616;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .popup-bg {
    position: fixed;
  }
  .popup-wrap {
    height: 100vh;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
  .popup-wrap {
    overflow-y: auto;
  }
}

@media screen and (max-width: 425px) {
  .popup-container {
    padding: 15px 20px;
  }
  .popup-top__title {
    font-size: 16px;
  }
  .popup-top {
    padding-bottom: 10px;
  }
}
</style>

