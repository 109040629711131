export default {
    name: "breadcrumbs",
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            params: {
                title: '',
                description: '',
                keywords: '',
                image: '',
            },
        }
    },
    'head': function () {

        return {
            script: function () {
                let crumbsCollection = []

                this.list.forEach((crumb, idx) => {

                    let src = `${crumb.src === 'home' ? '' : '/' + crumb.src}`
                    let slug = `${crumb.slug ? '/' + crumb.slug : ''}`
                    let url = window.location.host + src + slug

                    let obj = {
                        "@type": "ListItem",
                        "position": idx,
                        "name": crumb.title,
                        item: url
                    }
                    crumbsCollection.push(obj)
                })
                let obj = {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": crumbsCollection
                }

                const val = JSON.stringify(obj);

                return [
                    {
                        id: 'bread',
                        t: 'application/ld+json',
                        i: val
                    },
                ]
            }

        }

    },
    watch: {
        '$route'() {
            this.setMeta()
        },
        product: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.setMeta()
                }
            }
        }
    },
    mounted() {
    },
    created() {

    },
    methods: {
        goToPage(page) {
            this.$router.push({name: page}).catch(() => {
                console.log()
            })
        },
        setMeta() {
            this.$emit('updateHead');
        },
    }
}
