import {mapMutations} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
    name: "slider-items",
    components: {
        VueSlickCarousel
    },
    props: {
        item: {
            type: Object,
            default: () => {
            },
        },
    },
    data() {
        return {
            bigSlider: {
                "infinite": true,
                "centerPadding": "0",
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "initialSlide": 0,
                // "fade": true,
                arrows: false,
                centerMode: true,
                "responsive": [
                    {
                        "breakpoint": 720,
                        "settings": {
                            "centerPadding": "70px",
                        }
                    },
                    {
                        "breakpoint": 620,
                        "settings": {
                            "centerPadding": "150px",
                        }
                    },
                    {
                        "breakpoint": 550,
                        "settings": {
                            "centerPadding": "350px",
                        }
                    }
                ]
            },
            isPlayVideo: false,
        }
    },
    created() {
    },
    computed: {},
    methods: {
        playVideo() {
            this.isPlayVideo = !this.isPlayVideo
        },
        showNext() {
            this.$refs.slideItems.next()
        },
        showPrev() {
            this.$refs.slideItems.prev()
        },
        ...mapMutations({}),

    }
}
