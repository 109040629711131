import {mapMutations} from "vuex";

export default {
    name: "text-slider",
    props: {
        item: {
            type: Object,
            default: () => {
            },
        },
    },
    components: {},
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),
        carouselNav($event, direction) {
            const carousel = this.$refs['text-slider'];
            carousel.advancePage(direction);
        },
    }
}
