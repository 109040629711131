//sections
import {mapActions, mapGetters, mapMutations} from "vuex";
import specification from "@/modules/product-card/pages/specification/index.vue";
import axios from "axios";

export default {
    name: "arched_canopy",
    components: {
        specification
    },
    data() {
        return {
            specifications: [],
            captureToggle: false,
            x: 50,
            y: 129,
            afterStartPositionX: 0,
            afterStartPositionY: 0,
            startAnimation: true,
            yellow: 'gray',
            insertColor: '#70B4D1',
            roof: {
                width: 15,
                length: 1
            },
            roofWidth: 2000,
            roofLength: 2000,
        }
    },
    mounted() {

        this.getProductions({calculator_type_slug: 'arched_canopy'}).then(() => {
            this.roofWidth = this.productAttributes.sizes[0].scope
            this.roofLength = this.productAttributes.sizes[0].length
            // this.specificationList.specification.products = []
        })
    },
    created() {
    },
    watch: {
        roofWidth() {
            this.$refs.errorProductA1.innerText = ''
            let obj = {}
            obj.width = this.roofWidth
            obj.length = this.roofLength

            this.setRoofSize(obj)

            this.roof.width = this.roofWidth / 133.33
            if (this.roofWidth <= 2000 && this.roofWidth) {
                this.roof.width = this.roofWidth / 133.33
            }
            if (this.roofWidth <= 100 && this.roofWidth) {
                this.roof.width = 1
            }
            if (!this.roofWidth) {
                this.roof.width = 1
            }
            if (this.roofWidth >= 2000) {
                this.roof.width = 15 + (this.roofWidth / 133.33) / 100
            }
            if (this.roofWidth >= 70000) {
                this.roof.width = 21.5
            }


        },
        roofLength() {
            let obj = {}
            obj.width = this.roofWidth
            obj.length = this.roofLength

            this.setRoofSize(obj)

            this.$refs.errorProductD1.innerText = ''
            this.roof.length = 1 + (0.01 * this.roofLength) / 100
            if (this.roofLength <= 2000 && this.roofLength) {
                this.roof.length = 1
            }
            if (!this.roofLength) {
                this.roof.length = 0.6
            }
            if (this.roofLength >= 7000 && this.roofLength) {
                this.roof.length = 1.5
            }

        },
    },
    computed: {
        ...mapGetters({
            productAttributes: 'constructors/calculatorSpecifications',
            specificationList: 'constructors/specificationList',
            productsLoaded: 'constructors/productsLoaded',
            currentSpecification: 'constructors/currentSpecification',
            specificationsLoaded: 'constructors/specificationsLoaded'
        })
    },
    methods: {
        ...mapActions({
            getProductions: 'constructors/CET_ARCH_SPECIFICATION',
            getSpecifications: 'constructors/GET_SPECIFICATIONS',
            upd: 'basket/UPDATE_BASKET_CURRENT',
        }),
        ...mapMutations({
            setRoofSize: 'constructors/SET_ROOF_SIZE',
            saveTogetherProducts: 'basket/ADD_TOGETHER_PRODUCTS',
            resetSpecification: 'constructors/RESET_SPECIFICATIONS'
        }),
        addInputAnimated(item, classList, textItem, animText, border1, border1Class, border2, border2Class) {
            let border = document.querySelector(`.${border2}`).firstChild
            border.classList.add(border2Class)
            document.querySelector(`.${border1}`).classList.add(border1Class)
            document.getElementById(item).classList.add(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.add(animText)
            })
        },
        removeInputAnimated(item, classList, textItem, animText, border1, border1Class, border2, border2Class) {
            let border = document.querySelector(`.${border2}`).firstChild
            border.classList.remove(border2Class)
            document.querySelector(`.${border1}`).classList.remove(border1Class)
            document.getElementById(item).classList.remove(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.remove(animText)
            })
        },
        addInputAnimated2(item, classList, textItem, animText, border2, border2Class) {
            let border = Array.from(document.getElementsByClassName(border2))
            border.forEach(el => {
                el.classList.add(border2Class)
            })


            document.getElementById(item).classList.add(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.add(animText)
            })
        },
        removeInputAnimated2(item, classList, textItem, animText, border2, border2Class) {
            let border = Array.from(document.getElementsByClassName(border2))
            border.forEach(el => {
                el.classList.remove(border2Class)
            })

            document.getElementById(item).classList.remove(classList)
            let text = Array.from(document.getElementsByClassName(textItem))
            text.forEach(el => {
                el.classList.remove(animText)
            })
        },
        download() {
            const link = document.createElement("a");
            link.setAttribute('target', '_blank')
            link.href = this.specificationList.fileName.fileName
            link.download = 'specification.pdf';
            link.click();
        },
        getNewProducts() {

            let obj = {}
            obj.squares = [
                {
                    scope: parseInt(this.roofWidth),
                    length: parseInt(this.roofLength)
                }
            ]
            obj.calculator_type_slug = 'arched_canopy'
            obj.type = 'calculate'
            if (this.roofWidth >= 1000 && this.roofLength >= 1000) {
                this.getSpecifications(obj)
            }
            if (this.roofWidth < 1000) {
                this.$refs.errorProductA1.innerText = 'Мінімальне значення 1000мм'
            }
            if (this.roofLength < 1000) {
                this.$refs.errorProductD1.innerText = 'Мінімальне значення 1000мм'
            }

        },
        addToBasket() {
            this.specificationList.specification.products.forEach(el => {
                let obj = {}
                obj.id = el.id
                obj.select_type = el
                obj.select_type.amount = el.count
                obj.quantity = el.count
                this.saveTogetherProducts(obj)
            })
            this.upd().then(() => {
                this.$toasted.success(this.$t('basket.successAddBasket'));
            })
        }

    }
}

