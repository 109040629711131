import {mapMutations} from "vuex";
import {dynamicHead} from '@/mixins/dynamic-head.js'

export default {
    name: "seo-text",
    mixins: [dynamicHead],
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    components: {},
    data() {
        return {
            params: {
                title: '',
                description: '',
            }
        }
    },
    created() {
        if (this.item) {
            this.setParams()
            this.setMeta()
        }
    },
    computed: {},
    methods: {
        ...mapMutations({}),
        setParams() {
            this.params.title = this.item.title;
            this.params.description = this.item.content;

            // this.params.keywords = this.meta.metaKeywords;
            // this.params.image = this.meta.metaImage;
        }

    }
}
