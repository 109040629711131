import {mapMutations} from "vuex";
import catalogCategoryCard from "@/components/catalog-category-card/index.vue";

export default {
    name: "products",
    components: {
        catalogCategoryCard
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        item: {
            type: Object,
            default: () => {
            }
        },
        showTitle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),
    }
}
