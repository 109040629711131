import {mapGetters, mapMutations} from 'vuex';
import productQuestion from "@/components/popups/product-question/index.vue";
import productComment from "@/components/popups/product-comment/index.vue";
import registrationPopup from "@/components/popups/registration/index.vue";
import thanksPopup from "@/components/popups/thanks-popup/index.vue";
import fastOrder from "@/components/popups/fast-order/index.vue"
import calculatorMessage from "@/components/popups/calculator-message/index.vue";
import trialBatch from "@/components/popups/trial-batch/index.vue";
import authPopup from '@/components/popups/auth/index.vue'
import orderColor from "@/components/popups/order-color/index.vue";
import profileDocument from "@/components/popups/profile-document/index.vue";

export default {
    name: 'hidden',
    components: {
        productQuestion,
        productComment,
        registrationPopup,
        thanksPopup,
        fastOrder,
        calculatorMessage,
        trialBatch,
        authPopup,
        orderColor,
        profileDocument
    },
    computed: {
        ...mapGetters({
            showQuestionPopup: 'card/showQuestionPopup',
            questionId: 'card/questionId',
            showCommentPopup: 'card/showCommentPopup',
            showRegisterPopup: 'auth/showRegisterPopup',
            showThanksPopup: 'setting/showThanksPopup',
            showSuccessPopup: 'order/showSuccessPopup',
            isShowFastOrder: 'order/showFastOrder',
            isShowCalculatorMessage: 'constructors/messageContent',
            showSuccessPaymentPopup: 'order/showSuccessPaymentPopup',
            orderTrialBatch: 'landings/orderTrialBatch',
            showAuthPopup: 'auth/showAuthPopup',
            showAuthThanksPopup: 'auth/showAuthThanksPopup',
            showOrderColor: 'order/orderColorPopup',
            documentPopup: 'profile/documentPopup',
            showConsultationPopup: 'catalog/showConsultationPopup'
        })
    },
    methods: {
        ...mapMutations({
            closeQuestionPopup: 'card/SHOW_QUESTION_POPUP',
            closeCommentPopup: 'card/SHOW_COMMENT_POPUP',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',
            closeThanksPopup: 'setting/SHOW_THANKS_POPUP',
            closeSuccessPopup: 'order/SHOW_SUCCESS_POPUP',
            showFastOrder: 'order/FAST_ORDER_POPUP',
            showCalculatorMessage: 'constructors/SHOW_MESSAGE_POPUP',
            isShowTrialBatch: 'landings/ORDER_TRIAL_BATCH',
            isShowSuccessPaymentPopup: 'order/SHOW_SUCCESS_PAYMENT_POPUP',
            isShowAuthPopup: 'auth/SHOW_AUTH_POPUP',
            isShowAuthThanksPopup: 'auth/SHOW_AUTH_POPUP_THANKS',
            isShowOrderColor: 'order/SHOW_ORDER_COLOR_POPUP',
            isShowProfileDocument: 'profile/SET_DOCUMENT_POPUP',
            isShowConsultationPopup: 'catalog/SHOW_CONSULTATION_POPUP'
        })
    }
}
