import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '@/store'

Vue.use(VueRouter);

// const isAuthenticated = (to, from, next) => {
//   if (store.state.auth.isAuthenticated) {
//     next();
//     return
//   }
//   next('/sing-in')
// };

const routes = [
    {
        path: '/:lang(ru)?',
        name: 'home',
        component: () => import('@/modules/home/index.vue')
    },
    {
        path: '/:lang(ru)?/price',
        name: 'prices',
        component: () => import('@/modules/prices/index.vue')
    },
    {
        path: '/:lang(ru)?/site-map',
        name: 'site-map',
        component: () => import('@/modules/map/index.vue')
    },
    {
        path: '/:lang(ru)?/katalog',
        name: 'categories',
        component: () => import('@/modules/catalog/index.vue')
    },
    {
        path: '/:lang(ru)?/zgoda-na-obrobku-personalnikh-danikh',
        name: 'personal_data',
        component: () => import('@/modules/terms-pages/personal_data/index.vue')
    },
    {
        path: '/:lang(ru)?/privacy_policy',
        name: 'privacy_policy',
        component: () => import('@/modules/terms-pages/privacy_policy/index.vue')
    },
    {
        path: '/:lang(ru)?/dogovir-publichnoj-ofertu',
        name: 'public_offer',
        component: () => import('@/modules/terms-pages/public_offer/index.vue')
    },
    {
        path: '/:lang(ru)?/terms-of-use',
        name: 'terms_use',
        component: () => import('@/modules/terms-pages/terms_use/index.vue')
    },
    {
        path: '/:lang(ru)?/search',
        name: 'search',
        component: () => import('@/modules/search/index.vue')
    },
    {
        path: '/:lang(ru)?/katalog/:slug',
        name: 'category',
        component: () => import('@/modules/filter-products/index.vue')
    },
    {
        path: '/:lang(ru)?/video',
        name: 'video-gallery',
        component: () => import('@/modules/video-gallery/index.vue')
    },
    {
        path: '/:lang(ru)?/product/:slug',
        name: 'product',
        component: () => import('@/modules/product-card/index.vue'),
        redirect: '/:lang(ru)?/product/:slug',
        children: [
            {
                path: '/:lang(ru)?/product/:slug',
                name: 'all-about',
                component: () => import('@/modules/product-card/pages/all-about/index.vue'),
            },
            {
                path: '/:lang(ru)?/product/:slug/description',
                name: 'description',
                component: () => import('@/modules/product-card/pages/description/index.vue'),
            },
            {
                path: '/:lang(ru)?/product/:slug/characteristics',
                name: 'characteristics',
                component: () => import('@/modules/product-card/pages/characteristics/index.vue'),
            },
            {
                path: '/:lang(ru)?/product/:slug/comments',
                name: 'comments',
                component: () => import('@/modules/product-card/pages/comments/index.vue'),
            },
            {
                path: '/:lang(ru)?/product/:slug/question',
                name: 'question',
                component: () => import('@/modules/product-card/pages/question/index.vue'),
            },
            {
                path: '/:lang(ru)?/product/:slug/snow-load',
                name: 'snow-load',
                component: () => import('@/modules/product-card/pages/snow-load/index.vue'),
            },
            {
                path: '/:lang(ru)?/product/:slug/certificate',
                name: 'certificate',
                component: () => import('@/modules/product-card/pages/certificate/index.vue'),
            },
            {
                path: '/:lang(ru)?/product/:slug/roof',
                name: 'roof',
                component: () => import('@/modules/product-card/pages/roof-calculation/index.vue'),
            },

            {
                path: '/:lang(ru)?/product/:slug/:tab_slug',
                name: 'custom-page',
                component: () => import('@/modules/product-card/pages/custom-page/index.vue'),
            },

        ]
    },
    {
        path: '/:lang(ru)?/proektant',
        name: 'proektant',
        redirect: '/proektant/civil',
        component: () => import('@/modules/constructor/index.vue'),
        children: [
            {
                path: 'civil',
                name: 'civil',
                component: () => import('@/modules/constructor/pages/civil/index.vue'),
            },
            {
                path: 'industrial',
                name: 'industrial',
                component: () => import('@/modules/constructor/pages/industrial/index.vue'),
            },
            {
                path: 'sport_education',
                name: 'sport_education',
                component: () => import('@/modules/constructor/pages/sports/index.vue'),
            },
            {
                path: 'commercial',
                name: 'commercial',
                component: () => import('@/modules/constructor/pages/commercial/index.vue'),
            },
        ]
    },
    {
        path: '/:lang(ru)?/profile',
        redirect: '/profile/me',
        name: 'profile',
        component: () => import('../modules/profile/index.vue'),
        children: [
            {
                path: 'me',
                name: 'user-info',
                component: () => import('../modules/profile/section/user-info/index.vue'),
            },
            {
                path: 'bonuses',
                name: 'bonuses',
                component: () => import('../modules/profile/section/bonuses/index.vue'),
            },
            {
                path: 'orders',
                name: 'orders',
                component: () => import('../modules/profile/section/orders/index.vue'),
            },
            {
                path: 'history',
                name: 'history',
                component: () => import('../modules/profile/section/history/index.vue'),
            },
            {
                path: 'accounts',
                name: 'accounts',
                component: () => import('../modules/profile/section/accounts/index.vue'),
            },
            {
                path: 'accounts/:slug',
                name: 'accounts-item',
                component: () => import('../modules/profile/section/accounts-item/index.vue'),
            },
            {
                path: 'catalog',
                name: 'catalog',
                component: () => import('../modules/profile/section/catalog/index.vue'),
            },
            {
                path: 'constructor',
                name: 'profile-constructor',
                redirect: '/:lang(ru)?/profile/proektant/civil',
                component: () => import('@/modules/profile/section/constructor/index.vue'),
                children: [
                    {
                        path: 'civil',
                        name: 'civil',
                        component: () => import('@/modules/constructor/pages/civil/index.vue'),
                    },
                    {
                        path: 'industrial',
                        name: 'industrial',
                        component: () => import('@/modules/constructor/pages/industrial/index.vue'),
                    },
                    {
                        path: 'sport_education',
                        name: 'sport_education',
                        component: () => import('@/modules/constructor/pages/sports/index.vue'),
                    },
                    {
                        path: 'commercial',
                        name: 'commercial',
                        component: () => import('@/modules/constructor/pages/commercial/index.vue'),
                    },
                ]
            },
            {
                path: 'snow-load',
                name: 'profile-snow-load',
                component: () => import('../modules/product-card/pages/snow-load/index.vue'),
            },
            {
                path: 'calculator',
                name: 'profile-calculator',
                component: () => import('../modules/profile/section/calculator/index.vue'),
            }
        ]
    },

    {
        path: '/:lang(ru)?/basket',
        name: 'basket',
        component: () => import('@/modules/basket/index.vue')
    },
    {
        path: '/:lang(ru)?/ordering',
        name: 'ordering',
        component: () => import('@/modules/ordering/index.vue')
    },
    {
        path: '/:lang(ru)?/manuals',
        name: 'manuals',
        component: () => import('@/modules/manuals/index.vue'),
        redirect: '@/modules/manuals/components/category/index.vue',
        children: [
            {
                path: '/:lang(ru)?/manuals',
                name: 'manuals',
                component: () => import('@/modules/manuals/components/category/index.vue')
            },
            {
                path: '/:lang(ru)?/manuals/:slug',
                name: 'manuals-category',
                component: () => import('@/modules/manuals/components/manual/index.vue')
            },
        ]
    },
    // {
    //     path: '/:lang(ru)?/manual/:slug',
    //     name: 'manual',
    //     component: () => import('@/modules/manuals/components/manual/index.vue')
    // },
    {
        path: '/:lang(ru)?/news',
        name: 'articles',
        component: () => import('@/modules/articles/index.vue')
    },
    {
        path: '/:lang(ru)?/news/:slug',
        name: 'article',
        component: () => import('@/modules/articles/pages/article/index.vue')
    },
    {
        path: '/:lang(ru)?/projects',
        name: 'projects',
        component: () => import('@/modules/projects/index.vue')
    },
    {
        path: '/:lang(ru)?/projects/:slug',
        name: 'project',
        component: () => import('@/modules/projects/pages/project/index.vue')
    },
    {
        path: '/:lang(ru)?/about-us',
        name: 'about',
        component: () => import('@/modules/about/index.vue')
    },

    {
        path: '/:lang(ru)?/kontakty',
        name: 'contacts',
        component: () => import('@/modules/contacts/index.vue')
    },
    //
    /**
     * @deprecated use 'landing'
     */
    {
        path: '/:lang(ru)?/landing/:slug',
        redirect: '/:lang(ru)?/:slug',
    },
    {
        path: '/:lang(ru)?/partners/:slug',
        name: 'partners',
        component: () => import('@/modules/partners/index.vue')
    },
    {
        path: '/:lang(ru)?/page-not-found',
        name: 'page-not-found',
        component: () => import('@/modules/page-not-found/index.vue')
    },
    {
        path: '/:lang(ru)?/server-not-found',
        name: 'server-not-found',
        component: () => import('@/modules/server-not-found/index.vue')
    },
    {
        path: '/:lang(ru)?/:slug',
        name: 'landing',
        component: () => import('@/modules/landing/index.vue')
    },

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        let el = document.querySelector('.app');
        if (to.path !== from.path) {
            if (savedPosition) {
                return savedPosition
            } else {
                // el.scrollTop = 0;

                return {x: 0, y: 0}
            }
        }
    }
});

export default router
