<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header :headerData="headerDates[0].contacts" v-if="headerDates"/>
          <transition name="bounce">
            <router-view :key="$route.params.slug || $route.path"/>
          </transition>
        </div>
        <div id="html-overlay"></div>
        <div class="wrapper-bottom">
          <main-footer :footerData="footerDates[0].contacts" v-if="footerDates"/>
        </div>
      </div>
    </div>
    <hidden/>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>
<!--<script src="src/utils/PrefixFree.js"></script>-->
<script>

import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import {isMobile, isIPad} from '@/helpers/variables/index'

export default {
  name: 'app',
  components: {
    Hidden,
    MainHeader,
    MainFooter
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`,
      headerDates: 'setting/headerDates',
      footerDates: 'setting/footerDates'
    })
  },
  created() {
    /*this.redirectLink()*/
    this.getContacts({type: 'header'})
    this.getContacts({type: 'footer'})
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      getContacts: 'setting/GET_MAIN_CONTACTS'
    }),
    /*async redirectLink() {
      let path = this.$route.path;
      let lang = '';

      if (path.includes('/ru')) {
        lang = '/ru';
        path = path.replace('/ru', '');
      }

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({url_from: path})
      };

      const API_URL = process.env.VUE_APP_ROOT_API;
      const url = `${API_URL}v1/redirect-link`;

      const request = await fetch(url, requestOptions);
      const response = await request.json();

      if (response.data) {
        window.location.replace(lang + response.data.url_to);
      }
    }*/
  }
}
</script>

