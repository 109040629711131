import {mapMutations} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    name: "solution-slider",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            solutionSlider: {
                "autoplaySpeed":5000,
                "autoplay":true,
                "infinite": true,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "initialSlide": 0,
                arrows: false,


                "responsive": [
                    {
                        "breakpoint": 1200,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 1,
                        }
                    },
                    {
                        "breakpoint": 1100,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1,
                            "initialSlide": 2
                        }
                    },
                    {
                        "breakpoint": 850,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 1,
                        }
                    },
                    {
                        "breakpoint": 650,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1,
                        }
                    },
                    {
                        "breakpoint": 550,
                        "settings": {
                            "infinite": true,
                            "centerMode": true,
                            "centerPadding": "20px",
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "variableWidth": true
                        }
                    }
                ]
            }
        }
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),
        anchorScroll(item) {
            // this.openMenu()
            item = document.getElementById('calBack')

            if (item === null) {

                this.$router.push({name: 'home'}).then(() => {
                    const myEl = document.getElementById('calBack')
                    this.$smoothScroll({
                        scrollTo: myEl,
                    })
                }).catch(() => {
                    const myEl = document.getElementById('calBack')
                    this.$smoothScroll({
                        scrollTo: myEl,
                    })
                    console.log()
                })
            } else {

                const myEl = document.getElementById('calBack')
                this.$smoothScroll({
                    scrollTo: myEl,
                })
            }

        },
        showNext() {
            this.$refs.solutionSlider.next()
        },
        showPrev() {
            this.$refs.solutionSlider.prev()
        },
    }
}
