import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';

import {
    LOADING_ARTICLE_PAGE,
    LOADING_ARTICLES, MORE_ARTICLES,
    SET_ARTICLE_PAGE,
    SET_ARTICLES, SET_ARTICLES_PAGE, SET_PRICING_LIST,

} from '../mutation-types';

import {
    GET_ARTICLE_PAGE,
    GET_ARTICLES, GET_PRICING_LIST,
} from '../action-types';
import axios from "axios";

const state = {
    articles: [],
    articlesLoading: false,
    articlePage: '',
    articlePageLoading: false,
    limit: 12,
    pricingLists: ''
};

const getters = {
    pricingLists: state => state.pricingLists,
    articles: state => state.articles,
    articlesLoading: state => state.articlesLoading,
    articlePage: state => state.articlePage,
    articlePageLoading: state => state.articlePageLoading
};

const actions = {


    [GET_ARTICLES]: async ({commit}) => {
        commit(LOADING_ARTICLES, true);
        try {
            const response = await $http.get(`v1/articles?per_page=${state.limit}`);
            commit(SET_ARTICLES, response.data);
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_ARTICLES, false);
        }
    },
    [GET_PRICING_LIST]: async ({commit}) => {
        // commit(LOADING_ARTICLE_PAGE, true);
        try {
            const response = await $http.get(`v1/pricing-items`);
            commit(SET_PRICING_LIST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(LOADING_ARTICLE_PAGE, false);
        }
    },
    [GET_ARTICLE_PAGE]: async ({commit}, slug) => {
        commit(LOADING_ARTICLE_PAGE, true);
        try {
            const response = await $http.get(`v1/articles/${slug}`);
            commit(SET_ARTICLE_PAGE, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_ARTICLE_PAGE, false);
        }
    },

};

const mutations = {
    [SET_ARTICLES](state, data) {
        state.articles = data
    },
    [LOADING_ARTICLES](state, status) {
        state.articlesLoading = status
    },
    [SET_ARTICLE_PAGE](state, data) {
        state.articlePage = data
    },
    [LOADING_ARTICLE_PAGE](state, status) {
        state.articlePageLoading = status
    },
    [SET_ARTICLES_PAGE](state) {
        state.limit += 12
    },
    [SET_PRICING_LIST](state, data) {
        state.pricingLists = data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
