import {mapMutations} from "vuex";

export default {
    name: "title-video",
    props: {
        item: {
            type: Object,
            default: () => {
            },
        },
        text: {
            type: Boolean,
            default: false
        },
        video: {
            type: Boolean,
            default: false
        },
        image: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapMutations({}),
    }
}
