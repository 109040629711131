import {directive as onClickaway} from 'vue-clickaway';

export default {
    directives: {
        onClickaway: onClickaway,
    },
    props: {
        type: {
            type: String,
            default: 'objectData'
        },
        value: {},
        label: {
            type: String,
            default: ''
        },
        darkStyleColor: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        itemValue: {
            type: String,
            default: 'default'
        },
        itemTitle: {
            type: String,
            default: 'name'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: []
        },
        hideActiveClass: {
            type: Boolean,
            default: () => true
        }
    },
    data() {
        return {
            openSelect: false,
            selectedItem: this.value,
        }
    },
    watch: {
        value() {
            this.changeSelect(this.value)
        }
    },
    created() {
        this.selectedItem = this.value;
    },
    methods: {
        changeSelect(item) {
            this.openSelect = false;
            this.selectedItem = item;
            this.$emit('input', item);
            setTimeout(() => {
                this.openSelect = false;
            }, 200)
        },
        away: function () {
            this.openSelect = false
        },
        toggleSelect() {
            !this.openSelect ? this.openSelect = true : this.openSelect = false
        },
        changeEvent() {
            this.$emit('change-event')
        },
        selectItem(item) {
            this.$emit('select-item', item)
        },
        test() {
            this.$emit('test')
        }
    }
}
