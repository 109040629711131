var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"cover",staticClass:"cover"},[_c('div',{staticClass:"cover__wrapper"},[_c('div',{staticClass:"cover__container",class:{'cover__container-home':_vm.$route.name==='home'}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v(_vm._s(_vm.item.title))])]),(_vm.item.useFilter === 1)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"projects__selects-reset",on:{"click":_vm.resetFilters}},[_c('p',[_vm._v(_vm._s(_vm.$t('general.resetFilters')))])]),_c('div',{staticClass:"projects__selects"},_vm._l((_vm.categories),function(item,index){return _c('div',{staticClass:"projects__selects-select"},[_c('main-select',{attrs:{"readonly":true,"placeholder":_vm.$t('projects.select.' + item.type),"type":"objectData","item-value":"slug","item-title":"title","list":item.values,"value":item.slug},on:{"select-item":function($event){return _vm.findDuplicateSlug(index)}},model:{value:(_vm.payload[index].value),callback:function ($$v) {_vm.$set(_vm.payload[index], "value", $$v)},expression:"payload[index].value"}})],1)}),0)]):_vm._e()]),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.away),expression:"away"}],ref:"parentContainer",staticClass:"images"},_vm._l((_vm.groups),function(group){return _c('div',{staticClass:"images-group",class:{
      'images-group-1 images-group-custom' : group.length === 1,
      'images-group-2 images-group-custom' : group.length === 2,
      'images-group-3 images-group-custom' : group.length === 3,
      'images-group-4 images-group-custom' : group.length === 4,
      'images-group-5 images-group-custom' : group.length === 5
    }},_vm._l((group),function(item){return _c('div',{ref:"imageItem",refInFor:true,staticClass:"img-wrapper images-item"},[(!item.isVideo)?_c('router-link',{attrs:{"to":_vm.$localize({name:'project', params:{slug: item.targetSource.slug}})}},[_c('div',{staticClass:"desc-wrapper"},[_c('h2',[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.shortDescription)}})]),(!item.isVideo)?_c('img',{attrs:{"src":item.image,"alt":item.title}}):_vm._e()]):_vm._e(),(item.isVideo)?[_c('router-link',{attrs:{"to":_vm.$localize({name:'project', params:{slug: item.targetSource.slug}})}},[(_vm.videoId!==item.id)?_c('div',{staticClass:"desc-wrapper"},[_c('h2',[_vm._v(_vm._s(item.title))]),_c('p',{domProps:{"innerHTML":_vm._s(item.shortDescription)}})]):_vm._e(),(item.isYoutube)?_c('div',{staticClass:"video-player"},[_c('iframe',{attrs:{"src":`https://www.youtube.com/embed/${item.videoLink}?autoplay=1&mute=1&enablejsapi=1&loop=1`,"allowfullscreen":"","frameborder":"0","allow":"autoplay; encrypted-media","type":"text/html"}}),_c('div',{attrs:{"id":'video-id-'+item.id}})]):_c('video',{attrs:{"width":"100%","loop":"","autoplay":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":item.videoLink,"type":"video/mp4"}})])])]:_vm._e()],2)}),0)}),0),_c('div',{staticClass:"button__wrapper"},[_c('main-button',{attrs:{"label":_vm.item.buttonText,"load":false,"addSvg":true,"icon":'arrow.svg'},on:{"change-event":_vm.goToProjects}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }