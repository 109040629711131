import {mapActions, mapGetters, mapMutations} from "vuex";
import {chunk} from 'lodash'
import {directive as onClickaway} from 'vue-clickaway';
import cloneDeep from 'lodash/cloneDeep';

export default {
    name: "cover",
    directives: {
        onClickaway: onClickaway,
    },
    components: {},
    props: {
        list: {
            type: Array,
            default: () => []
        },
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            payload: [],
            groups: [],
            listProjects: [],
            ytVideos: [],
            isPlayVideo: false,
            videoId: '',
            categories: [],
            playerVars: {
                autoplay: 1,
                playsinline: 1,
            },
        }
    },
    mounted() {
        let keys = []
        let values = []
        if (this.item.useFilter === 1 ) {
            this.fetchFilters({section: this.item.id}).then(() => {
                Array.from(Object.keys(this.filters)).forEach(el => {
                    this.payload.push(({type: el, value: ''}))
                    keys.push(el)
                })
                Array.from(Object.values(this.filters)).forEach(el => {
                    values.push(el)
                })
                keys.forEach((el, idx) => {
                    this.categories.push({type: el, values: values[idx]})
                })

                let urlQuery = cloneDeep(this.$route.query);
                this.urlQuery = urlQuery

                let arr = []
                for (let key in urlQuery) {
                    arr.push(key + '=' + urlQuery[key])
                }
                let payload = {
                    section: this.item.id,
                    urlQuery: arr.join('&')
                }
                if(Object.keys(this.$route.query).length){
                    this.selectFilter(payload).then(() => {
                        let list = [], size = this.tilesCollection.data.length;
                        list[size - 1] = 5
                        let groupSize = 6;
                        this.groups = []
                        this.groups = chunk(this.tilesCollection.data, groupSize)
                        this.ytVideos = this.list
                            .filter((item) => {
                                return item.isVideo && item.isYoutube
                            }).map((item) => {
                                return {
                                    item,
                                    player: null
                                }
                            })
                        window.onYouTubeIframeAPIReady = this.loadPlayer;
                        const tag = document.createElement('script');
                        tag.src = 'https://www.youtube.com/iframe_api';
                        const firstScriptTag = document.getElementsByTagName('script')[0];
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                        setTimeout(() => {
                            this.loadPlayer()
                        }, 500)

                    })
                }else{
                    let list = [], size = this.list.length;
                    list[size - 1] = 5
                    let groupSize = 6;
                    this.ytVideos = this.list
                        .filter((item) => {
                            return item.isVideo && item.isYoutube
                        }).map((item) => {
                            return {
                                item,
                                player: null
                            }
                        })

                    this.groups = chunk(this.list, groupSize)
                    window.onYouTubeIframeAPIReady = this.loadPlayer;
                    const tag = document.createElement('script');
                    tag.src = 'https://www.youtube.com/iframe_api';
                    const firstScriptTag = document.getElementsByTagName('script')[0];
                    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                    setTimeout(() => {
                        this.loadPlayer()
                    }, 500)
                }

            })

        } else {
            let list = [], size = this.list.length;
            list[size - 1] = 5
            let groupSize = 6;
            this.ytVideos = this.list
                .filter((item) => {
                    return item.isVideo && item.isYoutube
                }).map((item) => {
                    return {
                        item,
                        player: null
                    }
                })

            this.groups = chunk(this.list, groupSize)
            window.onYouTubeIframeAPIReady = this.loadPlayer;
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            setTimeout(() => {
                this.loadPlayer()
            }, 500)
        }
    },
    playVideo() {
        this.isPlayVideo = !this.isPlayVideo
    },
    computed: {
        ...mapGetters({
            pageContent: 'setting/pageContent',
            filters: 'projects/tileFilters',
            tilesCollection: 'projects/tilesCollection'
        })
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({
            fetchFilters: 'projects/GET_PROJECTS_TILE_FILTERS',
            selectFilter: 'projects/GET_TILES'
        }),
        findDuplicateSlug(index) {
            let key = this.payload[index].type;
            let val = this.payload[index].value.slug;

            let urlQuery = cloneDeep(this.$route.query);

            if (urlQuery[key] !== val) {
                urlQuery[key] = val
            } else {
                delete urlQuery[key];
            }

            this.urlQuery = urlQuery
            this.$router.replace({
                name: 'landing',
                params: this.$route.params,
                query: urlQuery
            }).then(() => {

            })
            let arr = []
            for (key in urlQuery) {
                arr.push(key + '=' + urlQuery[key])
            }
            let payload = {
                section: this.item.id,
                urlQuery: arr.join('&')
            }
            this.selectFilter(payload).then(() => {
                let list = [], size = this.tilesCollection.data.length;
                list[size - 1] = 5
                let groupSize = 6;
                this.groups = []
                 this.groups = chunk(this.tilesCollection.data, groupSize)

            })
        },
        resetFilters() {
            this.$router.replace({
                name: 'landing',
                params: '',
                query: ''
            })
            this.payload.forEach(el => {
                return el.value = ''
            })
            let list = [], size = this.list.length;
            list[size - 1] = 5
            let groupSize = 6;
            this.ytVideos = this.list
                .filter((item) => {
                    return item.isVideo && item.isYoutube
                }).map((item) => {
                    return {
                        item,
                        player: null
                    }
                })
            this.groups = chunk(this.list, groupSize)
            window.onYouTubeIframeAPIReady = this.loadPlayer;
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            setTimeout(() => {
                this.loadPlayer()
            }, 500)

            this.ytVideos = this.list
                .filter((item) => {
                    return item.isVideo && item.isYoutube
                }).map((item) => {
                    return {
                        item,
                        player: null
                    }
                })

            this.groups = chunk(this.list, groupSize)
        },
        loadPlayer() {
            this.ytVideos
                .map(({item}) => {
                    const link = item.videoLink;
                    const id = 'video-id-' + item.id

                    const player = new YT.Player(id, {
                        height: '360',
                        width: '600',
                        videoId: `${link}`,
                        playerVars: {
                            autoplay: 1,
                            controls: 0,
                            modestbranding: 1,
                            loop: 1,
                            autohide: 0,
                            mute: 1,
                            playsinline: 1,
                            playlist: `${link}`,
                        },
                        events: {
                            onReady: this.onPlayerReady,
                        },
                    })
                    return {
                        item,
                        player,
                    }
                })
            ;
        },
        onPlayerReady(event) {
            event.target.setVolume(50);
            event.target.playVideo();
        },
        goToProjects() {
            this.$router.push({name: 'projects'}).catch(() => {
                console.log()
            })
        },
        playVideo(item) {
            this.isPlayVideo = !this.isPlayVideo
            this.videoId = item.id
        },
        away: function () {
            this.isPlayVideo = !this.isPlayVideo
            this.videoId = ''
        },
        goTo(item) {
            this.$router.push({name: 'project', params: {slug: item.targetSource.slug}})
        },
    }
}
