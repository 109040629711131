<template>
  <div class="small-loader">
    <div id="small-loader">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'small-loader',
}
</script>
<style lang="scss">
.small-loader {
  width: 150px;
  height: 100px;

  .bar {
    position: absolute;
    animation: jump 1s ease infinite;
    -webkit-animation: jump 1s ease infinite;
    border-radius: 2px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .1));
  }

  .bar:nth-child(1) {
    left: 0;
  }

  .bar:nth-child(2) {
    left: 20px;
    animation-delay: .1s;
    -webkit-animation-delay: .1s;
  }

  .bar:nth-child(3) {
    left: 40px;
    animation-delay: .2s;
    -webkit-animation-delay: .2s;
  }

  .bar:nth-child(4) {
    left: 60px;
    animation-delay: .3s;
    -webkit-animation-delay: .3s;
  }

  .bar:nth-child(5) {
    left: 80px;
    animation-delay: .4s;
    -webkit-animation-delay: .4s;
  }

  .bar:nth-child(6) {
    left: 100px;
    animation-delay: .5s;
    -webkit-animation-delay: .5s;
  }

  .bar:nth-child(7) {
    left: 120px;
    animation-delay: .6s;
    -webkit-animation-delay: .6s;
  }

  .bar:nth-child(8) {
    left: 140px;
    animation-delay: .7s;
    -webkit-animation-delay: .7s;
  }

  @-webkit-keyframes jump {
    0% {
      width: 20px;
      height: 180px;
      margin-left: 0;
      margin-top: 0;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 2px 2px rgba(0, 0, 0, .7);
    }
    50% {
      width: 26px;
      height: 200px;
      margin-left: -3px;
      margin-top: -10px;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 20px 25px rgba(0, 0, 0, .7);
    }
    100% {
      width: 20px;
      height: 180px;
      margin-left: 0;
      margin-top: 0;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 2px 2px rgba(0, 0, 0, .7);
    }
  }
}

</style>
