import {mapActions, mapMutations, mapGetters} from "vuex";
import categoryListCard from "@/components/category-list-card/index.vue";

export default {
    name: "category-list",
    components: {
        categoryListCard
    },
    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: '/catalog', title: this.$t('products.wholesale'), slug: 'catalog'}
            ],
        }
    },
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({})
    },

    methods: {
        ...mapActions({}),
        ...mapMutations({
            isShowConsultationPopup: 'catalog/SHOW_CONSULTATION_POPUP'
        }),
        collMe() {
            this.isShowConsultationPopup(true)
        }
    }
}
