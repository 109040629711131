import {mapMutations, mapActions, mapGetters} from "vuex";

//sections
import SmallLoader from "@/components/small-loader/index.vue";
import {loginByPhone} from "@/mixins/loginByPhone.js";

export default {
    name: "registration",
    components: {
        SmallLoader,
    },
    mixins: [loginByPhone],
    data() {
        return {
            selectedService: [],
        }
    },
    created() {
    },
    mounted() {

    },
    watch: {},
    computed: {
        ...mapGetters({})
    },
    methods: {
        ...mapMutations({
            setToken: 'auth/SET_TOKEN',
            isShowAuthPopup: 'auth/SHOW_AUTH_POPUP',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',
        }),
        showAuthPopup() {
            this.closeRegisterPopup(false)
            this.isShowAuthPopup(true)
        },
        ...mapActions({}),
    }
}
