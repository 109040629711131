import {mapMutations, mapGetters} from "vuex";

export default {
    name: "catalog-category-card",
    props: {
        item: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {}
    },
    created() {


    },
    methods: {},
}
